/* eslint-disable no-unused-vars */
import apiRequest from "./apiRequest";

const authService = {
    login: async (data) => {
        try {
            const res = await apiRequest({
                url: `/guide-login`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL
            })
            const resData = res.data;

            if (resData.status === "OK") {
                // console.log(resData.data.guide.id)
                return {
                    isPresent: true,
                    data: resData.data
                }
            } else {
                return {
                    isPresent: false,
                    data: null
                }
            }
        } catch (e) {
            throw Error("Something went wrong while sending request to check phone")
        }
    },
    forgotPassword: async (data) => {
        try {
            const res = await apiRequest({
                url: `/guide-forget-password`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL
            })
            const resData = res.data;

            if (resData.status === "OK") {
                // console.log(resData.data.guide.id)
                return {
                    isPresent: true,
                    data: resData.data
                }
            } else {
                return {
                    isPresent: false,
                    data: null
                }
            }
        } catch (e) {
            throw Error("Something went wrong while sending request to check phone")
        }
    },
    resetPassword: async (data) => {
        try {
            const res = await apiRequest({
                url: `/guide-reset-password`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL
            })
            const resData = res.data;

            if (resData.status === "OK") {
                return {
                    isPresent: true,
                    data: resData.data
                }
            } else {
                return {
                    isPresent: false,
                    data: null
                }
            }
        } catch (e) {
            throw Error("Something went wrong while sending request to check phone")
        }
    },
    login2: async (data) => {
        try {
            const res = await apiRequest({
                url: `/guide-find/${data}`,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL
            })
            const resData = res.data;

            if (resData.status === "OK") {
                // console.log(resData.data.guide.id)
                return {
                    isPresent: true,
                    data: resData.data
                }
            } else {
                return {
                    isPresent: false,
                    data: null
                }
            }
        } catch (e) {
            throw Error("Something went wrong while sending request to check phone")
        }
    },
    register: async (data) => {
        try {
            const phoneToString = data.phone.toString();
            const res = await apiRequest({
                url: `/guide-store`,
                requestMethod: "POST",
                requestBody: data,
                baseURL: process.env.VUE_APP_MS_PEV_API_BASE_URL
            });
            const resData = res.data;
            console.log(resData)
            if (resData.status === "OK") {
                return {
                    isPresent: true,
                    data: resData.data
                }
            } else {
                return {
                    isPresent: false,
                    data: null
                }
            }
        } catch (error) {
            throw new Error("Error registering user");
        }
    },
}


export default authService