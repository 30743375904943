<script>
import authService from "@/services/authService";
import LoaderComponent from "@/components/LoaderComponent.vue";
import { routesData } from "@/route.config";

export default {
  components: { LoaderComponent },
  name: "ForgotPasswordPage",

  data() {
    return {
      isValidPhone: true,
      isFormLoading: false,
      isFormError: false,
      phone: "",
      isNextStep: false,
      routes: routesData,
    };
  },
  methods: {
    async formHandler() {
      try {
        (this.isFormError = false), (this.isFormLoading = true);
        //   console.log("phone: ", this.phone);
        const payload = {
          phone: this.phone.toString(),
        };
        const resData = await authService.forgotPassword(payload);

        if (resData.isPresent) {
          this.isNextStep = true;
        } else {
          this.$router.push(this.routes.login.path);
          // throw new Error("Something went wrong");
        }
      } catch (error) {
        this.isFormError = true;
        console.log("Something went wrong,", error);
      } finally {
        this.isFormLoading = false;
      }
    },

    validatePhone() {
      const regex = /^(\+?237)?6((8|7|9|2)\d{7}|5([0-4]|[5-9])\d{6})$/;
      this.isValidPhone = regex.test(this.phone);
    },
  },
  mounted() {},
};
</script>

<template>
  <div class="flex flex-grow justify-center px-4">
    <template v-if="isFormLoading"> <loader-component /> </template>
    <template v-else>
      <template v-if="isFormError">
        <error-handler-component :submit-handler="formHandler" />
      </template>
      <template v-else>
        <div
          class="flex w-full flex-grow flex-col justify-center gap-y-2 rounded"
        >
          <template v-if="isNextStep">
            <div class="flex flex-grow flex-col justify-between px-2 pb-4 pt-8">
              <div class="flex flex-grow flex-col justify-center pb-32">
                <div>
                  <h1 class="mb-2 text-4xl font-bold text-black">
                    Code envoyé !
                  </h1>
                  <h2 class="text-xl font-medium text-black">
                    Un sms a été envoyé au numéro
                    <span class="font-semibold text-black">{{ phone }}</span
                    >, utilisez le mot de passe pour vous connecter
                  </h2>
                </div>
              </div>
              <div class="flex flex-grow flex-col justify-end gap-y-2">
                <router-link
                  to="/"
                  class="btn btn-success bg-success bg-green-500 py-2 text-white"
                >
                  Cliquez pour revenir à la page d'accueil
                </router-link>
              </div>
            </div>
          </template>
          <template v-else>
            <form @submit.prevent="formHandler">
              <div class="mb-2 flex flex-col">
                <h1 class="text-xl font-bold">Récupération de compte</h1>
                <h2 class="text-sm text-black">
                  Pour récupérer votre compte , veuillez renseigner votre numéro
                  de téléphone
                </h2>
              </div>
              <div class="flex flex-col gap-y-2">
                <div>
                  <input
                    id="phone"
                    v-model="phone"
                    type="number"
                    required
                    placeholder="Entrez un numéro de téléphone"
                    autofocus
                    maxlength="12"
                    autocomplete="off"
                    class="w-full rounded-md border border-slate-400 bg-slate-50 px-1 py-3 text-center text-sm font-medium leading-3"
                    :class="{
                      ' text-red-800 outline outline-red-200 border-none bg-white':
                        !isValidPhone && phone !== '',
                    }"
                    @input="validatePhone"
                  />
                  <p
                    v-if="!isValidPhone && phone !== ''"
                    class="p-1 text-xs text-red-500"
                  >
                    Numéro de téléphone invalide
                  </p>
                </div>
              </div>
              <div class="mt-3 flex flex-col gap-y-3">
                <div class="">
                  <button
                    type="submit"
                    v-if="!isFormLoading"
                    class="text-md w-full rounded bg-green-700 p-3 text-center font-semibold text-white"
                    :class="{ 'bg-slate-300': !isValidPhone && phone !== '' }"
                    :disabled="!isValidPhone && phone !== ''"
                  >
                    Récupérez maintenant
                  </button>
                  <LoaderComponent v-else />
                </div>

                <back-button
                  :to="routes.login.path"
                  class="text-center text-sm no-underline"
                />
              </div>
            </form>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>
