<script>
import { routesData } from "@/route.config";
import StateStorage from "@/utils/StateStorage";
import authService from "@/services/authService";
import { CModal, CModalHeader, CModalBody, CModalFooter } from "@coreui/vue";
import ModalInputItem from "../../components/ui/ModalInputItem.vue";

export default {
  name: "RegisterPage",
  components: {
    CModal,
    CModalHeader,
    CModalBody,
    CModalFooter,
    ModalInputItem,
  },
  data() {
    return {
      personType: "",
      isValidPhone: true,
      routes: routesData,
      form: {
        last_name: "",
        first_name: "",
        sex: "",
        age: "",
        national_cardId: "",
        residence: "",
        phone: "",
        date_of_birth: "",
      },
      isConfirmRegisterModalOpen: false,
      isRegisterLoading: false,
      isRegisterError: false,
    };
  },
  methods: {
    format(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async formHandler() {
      try {
        this.isRegisterError = false;
        this.isConfirmRegisterModalOpen = false;
        this.isRegisterLoading = true;
        const payload = {
          ...this.form,
          phone: this.form.phone.toString(),
          age: null,
          person_type: parseInt(this.personType),
        };
        console.log(payload);
        const resData = await authService.register(payload);

        if (resData.isPresent) {
            StateStorage.set("phone", this.form.phone);

            if(resData.data) {
                this.$router.push(`${this.routes.login.path}?is_account_old=true`);
            } else {
                this.$router.push(`${this.routes.login.path}?is_account_new=true`);
            }

          //   this.$router.push(
          //     `/patients?phone=${this.form.phone}&guide_id=${resData.data.id}&person_type=${this.personType}`
          //   );
        } else {
          this.isRegisterError = true;
        }
      } catch (error) {
        this.isRegisterError = true;
        console.log("Something went wrong,", error);
      } finally {
        this.isRegisterLoading = false;
      }
    },
    toggleConfirmModal() {
      this.isConfirmRegisterModalOpen = true;
    },
    validatePhone() {
      const regex = /^(\+?237)?6((8|7|9|2)\d{7}|5([0-4]|[5-9])\d{6})$/;
      this.isValidPhone = regex.test(this.form.phone);
    },
  },
  mounted() {
    this.personType = this.$route.query.person_type;
  },
};
</script>
<template>
  <div class="flex w-full flex-grow flex-col justify-center">
    <template v-if="isRegisterLoading"> <content-section-loading /> </template>
    <template v-else>
      <template v-if="isRegisterError">
        <error-handler-component :submit-handler="formHandler" />
      </template>
      <template v-else>
        <div class="flex flex-grow flex-col justify-between px-3 pb-4 pt-8">
          <div class="flex flex-grow flex-col">
            <div class="flex flex-grow flex-col">
              <h1 class="mb-2 text-3xl font-bold text-black">
                Création de compte
              </h1>
              <h2 class="text-xl font-semibold text-black">
                Veuillez remplir les champs ci-dessous
              </h2>
              <form
                @submit.prevent="toggleConfirmModal"
                class="mt-4 flex flex-col gap-y-3"
              >
                <!-- First Name -->
                <div class="flex flex-col gap-x-2">
                  <label class="font-bold" for="first_name"
                    >Prénom <span class="text-red-400">*</span></label
                  >
                  <input
                    v-model="form.first_name"
                    type="text"
                    class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                    name="first_name"
                    id="first_name"
                    required
                    placeholder="Entrez votre prénom"
                  />
                </div>
                <!-- Last Name -->
                <div class="flex flex-col gap-x-2">
                  <label class="font-bold" for="last_name"
                    >Nom de famille <span class="text-red-400">*</span></label
                  >
                  <input
                    v-model="form.last_name"
                    type="text"
                    class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                    name="last_name"
                    id="last_name"
                    required
                    placeholder="Entrez votre nom de famille"
                  />
                </div>

                <!-- Sex -->
                <div class="flex flex-col gap-x-2">
                  <label class="font-bold" for="sex">Sexe </label>
                  <select
                    v-model="form.sex"
                    type="text"
                    class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                    name="sex"
                    id="sex"
                    required
                    placeholder="Choisissez votre sexe"
                  >
                    <option value="" disabled selected>
                      Choisissez votre sexe
                    </option>
                    <option value="M">Homme</option>
                    <option value="F">Femme</option>
                    <!-- <option value="Other">Prefer not to say</option> -->
                  </select>
                </div>
                <!-- Date of birth -->
                <div class="flex flex-col gap-x-2">
                  <label class="font-bold" for="date_of_birth"
                    >Date de naissance
                    <span class="text-red-400">*</span></label
                  >
                  <VueDatePicker
                    v-model="form.date_of_birth"
                    model-type="dd/MM/yyyy"
                    placeholder="Cliquez pour choisir une date"
                    :format="format"
                    required
                    :enable-time-picker="false"
                  />
                </div>

                <div class="flex flex-col gap-x-2">
                  <label class="font-bold" for="phone">
                    Numéro de téléphone
                    <span class="text-red-400">*</span></label
                  >
                  <input
                    v-model="form.phone"
                    type="number"
                    class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                    name="phone"
                    id="phone"
                    maxlength="12"
                    required
                    autocomplete="off"
                    placeholder="Entrez votre numéro de téléphone"
                    :class="{
                      ' text-red-800 outline outline-red-200 border-none bg-white':
                        !isValidPhone,
                    }"
                    @input="validatePhone"
                  />
                </div>
                <!-- National ID Card -->
                <div class="flex flex-col gap-x-2">
                  <label class="font-bold" for="national_cardId"
                    >Numéro de CNI <span class="text-red-400">*</span></label
                  >
                  <input
                    v-model="form.national_cardId"
                    type="text"
                    class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                    name="national_cardId"
                    id="national_cardId"
                    required
                    placeholder="Entrez votre numéro de CNI"
                  />
                </div>

                <!-- Residence address -->
                <div class="flex flex-col gap-x-2">
                  <label class="font-bold" for="residence"
                    >Adresse de résidence
                    <span class="text-red-400">*</span></label
                  >
                  <input
                    v-model="form.residence"
                    type="text"
                    class="text-md w-full rounded border border-slate-500 bg-slate-50 px-2 py-2 leading-6"
                    name="residence"
                    id="residence"
                    required
                    placeholder="Entrez votre résidence"
                  />
                </div>
                <div class="mt-2 flex flex-grow flex-col justify-end gap-y-2">
                  <button
                    type="submit"
                    :disabled="!isValidPhone"
                    :class="{ 'bg-slate-300': !isValidPhone }"
                    class="btn btn-success bg-success py-2 text-white"
                  >
                    Créer mon compte
                  </button>
                  <back-button :href="routes.login.path"></back-button>
                </div>
              </form>
              <template v-if="true">
                <CModal
                  alignment="center"
                  scrollable
                  backdrop="static"
                  :visible="isConfirmRegisterModalOpen"
                  @close="
                    () => {
                      isConfirmRegisterModalOpen = false;
                    }
                  "
                  aria-labelledby="VerticallyCenteredExample"
                >
                  <CModalHeader>
                    <CModalTitle id="VerticallyCenteredExample"
                      >Confirmer les informations renseignées
                    </CModalTitle>
                  </CModalHeader>
                  <CModalBody>
                    <div>
                      <modal-input-item
                        label="Prénom"
                        :value="form.first_name"
                      />
                      <modal-input-item
                        label="Nom de famille"
                        :value="form.last_name"
                      />
                      <modal-input-item label="Sexe" :value="form.sex" />

                      <modal-input-item
                        label="Date de naissance"
                        :value="form.date_of_birth"
                      />
                      <modal-input-item
                        label="Numéro de téléphone principal"
                        :value="form.phone"
                      />

                      <modal-input-item
                        label="National ID Number"
                        :value="form.national_cardId"
                      />
                      <modal-input-item
                        label="Adresse de résidence"
                        :value="form.residence"
                      />
                    </div>
                  </CModalBody>
                  <CModalFooter>
                    <div class="d-grid w-full gap-y-2">
                      <button
                        @click="formHandler"
                        class="btn btn-success text-white"
                      >
                        Enregistrer
                      </button>
                      <button
                        class="btn btn-info text-white"
                        @click="
                          () => {
                            isConfirmRegisterModalOpen = false;
                          }
                        "
                      >
                        Modifier
                      </button>
                    </div>
                  </CModalFooter>
                </CModal>
              </template>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
