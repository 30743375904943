<script>
import PatientComponent from "@/components/PatientComponent.vue";
import patientService from "@/services/patientService";
import ErrorHandlerComponent from "@/components/ErrorHandlerComponent.vue";
import { routesData } from "@/route.config";
import StateStorage from "@/utils/StateStorage";

export default {
  components: { PatientComponent, ErrorHandlerComponent },
  name: "ListPatientPage",
  data() {
    return {
      patientListData: [],
      guideData: "",
      guideId: "",
      phoneNumber: "",
      personType: "",
      isPatientListLoading: false,
      isPatientListError: false,
      routes: routesData,
    };
  },
  methods: {
    async getAllPatientsRelatedToPhone(phoneNumber) {
      //   console.log("Getting user data with phone: ", phoneNumber);

      try {
        this.isPatientListLoading = true;
        const resData = await patientService.getAllPatients(phoneNumber);
        return resData;
      } catch (error) {
        this.isPatientListError = true;
      } finally {
        this.isPatientListLoading = false;
      }
    },
    async initiatePatientsData() {
      this.isPatientListError = false;
      try {
        const phone = this.$route.query.phone;
        this.phoneNumber = phone;
        const resData = await this.getAllPatientsRelatedToPhone(phone);
        this.patientListData = resData.patients ?? [];
        this.guideData = resData.guide;

        if (!resData.guide) {
          this.$router.push("/");
        }
      } catch (error) {
        this.isPatientListError = true;
      }
    },
    logoutUserHandler() {
      StateStorage.clear();
      this.$router.push("/");
    },
  },

  async mounted() {
    this.personType = this.$route.query.person_type;
    this.guideId = this.$route.query.guide_id;
    this.initiatePatientsData();
  },
};
</script>

<template>
  <template v-if="isPatientListLoading">
    <content-section-loading />
  </template>
  <template v-else>
    <template v-if="isPatientListError">
      <error-handler-component :submit-handler="initiatePatientsData" />
    </template>
    <template v-else>
      <div class="flex flex-grow flex-col gap-y-1 py-3">
        <template v-if="patientListData.length === 0">
          <div class="flex flex-grow flex-col items-center justify-center">
            <span>Aucun patient enregistré </span>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-grow flex-col rounded-sm">
            <div class="py-3 text-center text-xl font-semibold">
              Liste des patients
            </div>
            <div
              class="mb-1 flex h-[300px] flex-grow flex-col gap-y-4 overflow-y-scroll px-3 pb-2"
            >
              <template
                v-for="(patientData, index) in patientListData"
                :key="patientData.id"
              >
                <patient-component
                  :no="index + 1"
                  :person-type="personType"
                  :guide-id="guideId"
                  :guide-number="phoneNumber"
                  :patient="patientData"
                />
              </template>
            </div>
          </div>
        </template>
        <div class="flex flex-col gap-y-2 px-3">
          <router-link
            v-if="guideData.is_patient === 0"
            :to="`${routes.patientCreate.path}??phone=${phoneNumber}&guide_id=${guideId}&is_patient=1`"
            class="btn btn-warning btn-md bg-yellow-900 py-2 text-white"
            >Voulez-vous être un patient? Cliquez ici
          </router-link>
          <router-link
            :to="`${routes.patientCreate.path}?phone=${phoneNumber}&guide_id=${guideId}`"
            class="btn btn-success btn-md py-2 text-white"
          >
            Enregistrer un nouveau patient
          </router-link>
          <router-link
            :to="`${routes.patientSearch.path}?phone=${phoneNumber}&guide_id=${guideId}`"
            class="btn btn-primary btn-md bg-blue-700 py-2 text-white"
          >
            Rechercher un patient existant
          </router-link>
          <button @click="logoutUserHandler" class="btn btn-dark">
            Deconnexion
          </button>
        </div>
      </div>
    </template>
  </template>
</template>
