<script>
import { routesData } from "@/route.config";
export default {
  name: "SuccessPage",
  data() {
    return {
      phone: "",
      guideId: "",
      routes: routesData,
    };
  },
  mounted() {
    this.phone = this.$route.query.phone;
    this.guideId = this.$route.query.guide_id;
  },
};
</script>
<template>
  <div class="flex flex-grow flex-col justify-between px-2 pb-4 pt-8">
    <div class="flex flex-grow flex-col justify-center pb-32">
      <div>
        <h1 class="mb-2 text-4xl font-bold text-black">Félicitation!</h1>
        <h2 class="text-xl font-medium text-black">
          Merci d'avoir complété votre enregistrement. Veuillez finaliser le
          processus pour recevoir vos rappels vaccinaux par SMS
        </h2>
      </div>
    </div>
    <div class="flex flex-grow flex-col justify-end gap-y-2">
      <router-link
        :to="`${routes.patientList.path}?guide_id=${guideId}&phone=${phone}`"
        class="btn btn-success bg-success bg-green-500 py-2 text-white"
      >
        Revenir à la page accueil
      </router-link>
    </div>
  </div>
</template>
