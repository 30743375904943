<script>
import StateStorage from "@/utils/StateStorage";
import authService from "@/services/authService";
import { routesData } from "@/route.config";

export default {
  components: {},
  name: "LoginPage",
  data() {
    return {
      isValidPhone: true,
      isPasswordVisible: false,
      isAccountPresent: true,
      isLoginLoading: false,
      isLoginError: false,
      phone: "",
      password: "",
      routes: routesData,
    };
  },
  methods: {
    async loginHandler() {
      this.isLoginError = false;
      this.isAccountPresent = false;

      try {
        this.isLoginLoading = true;
        const phoneToString = this.phone.toString();
        const payload = {
          phone: phoneToString,
          password: this.password,
        };
        const resData = await authService.login(payload);
        StateStorage.set("phone", phoneToString);

        if (resData.isPresent) {
          StateStorage.set("phone", phoneToString);
          StateStorage.set("guide", resData.data.guide);
          let redirectURL = "";

          console.log(resData.data.guide.is_first);
          console.log("is first time: ", typeof resData.data.guide.is_first);

          if (resData.data.guide.is_first === 0) {
            redirectURL = `/patients?phone=${this.phone}&guide_id=${resData.data.guide.id}`;
          } else {
            redirectURL = `/reset-password`;
          }

          this.$router.push(redirectURL);
        } else {
          this.isAccountPresent = false;
          // this.$router.push(this.routes.register.path);
        }
      } catch (error) {
        this.isLoginError = true;
        console.log("Something went wrong,", error);
      } finally {
        this.isLoginLoading = false;
      }
    },

    validatePhone() {
      const regex = /^(\+?237)?6((8|7|9|2)\d{7}|5([0-4]|[5-9])\d{6})$/;
      this.isValidPhone = regex.test(this.phone);
    },
    togglePassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
  mounted() {},
};
</script>

<template>
  <div class="flex flex-grow justify-center px-4">
    <template v-if="isLoginLoading"> <content-section-loading /> </template>
    <template v-else>
      <template v-if="isLoginError">
        <error-handler-component :submit-handler="loginHandler" />
      </template>
      <template v-else>
        <form
          @submit.prevent="loginHandler"
          class="flex w-full flex-grow flex-col justify-center gap-y-2 rounded"
        >
          <div class="mb-2 flex flex-col">
            <h1 class="text-xl font-bold">
              Bienvenue sur la plateforme de rappel vaccinal PEV Cameroun
            </h1>
            <h2 class="text-sm text-black">
              Pour vous connecter, veuillez renseigner votre numéro de téléphone
              et mot de passe
            </h2>
          </div>
          <div class="flex flex-col gap-y-2">
            <h6
              v-if="!isAccountPresent"
              class="rounded-md bg-red-200 p-2 text-sm text-red-700"
            >
              Mauvais téléphone ou mot de passe
            </h6>
            <h6 v-if="$route.query.is_account_old ?? false" class="rounded-md bg-yellow-300 p-2 text-sm text-yellow-700" >Un compte est déjà associé à ce numéro</h6>
            <h6 v-if="$route.query.is_account_new ?? false" class="rounded-md bg-green-300 p-2 text-sm text-green-700" >Compte enregistré avec succès</h6>
            <div>
              <input
                id="phone"
                v-model="phone"
                type="number"
                required
                placeholder="Entrez votre numéro de téléphone"
                maxlength="12"
                autocomplete="off"
                class="w-full rounded-md border border-slate-400 bg-slate-50 px-1 py-3 text-center text-sm font-medium leading-3"
                :class="{
                  ' text-red-800 outline outline-red-200 border-none bg-white':
                    !isValidPhone,
                }"
                @input="validatePhone"
              />
              <p v-if="!isValidPhone" class="p-1 text-xs text-red-500">
                Numéro de téléphone invalide
              </p>
            </div>
            <div>
              <div class="flex items-center">
                <input
                  id="password"
                  v-model="password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  required
                  placeholder="Entrez votre mot de passe"
                  autocomplete="off"
                  class="w-full rounded-md border border-slate-400 bg-slate-50 px-1 py-3 text-center text-sm font-medium leading-3"
                />
                <v-icon
                  name="bi-eye-fill"
                  v-if="isPasswordVisible"
                  @click="togglePassword"
                  scale="1.5"
                  class="-ml-8 cursor-pointer p-1"
                />
                <v-icon
                  name="bi-eye-slash-fill"
                  v-else
                  @click="togglePassword"
                  class="-ml-8 cursor-pointer p-1"
                  scale="1.5"
                />
              </div>
              <div class="flex items-center justify-end py-1">
                <router-link
                  :to="routes.forgotPassword.path"
                  class="text-right text-sm text-blue-700 no-underline"
                  >Mot de passe oublié?
                </router-link>
              </div>
            </div>
          </div>
          <div class="mt-3 flex flex-col gap-y-3">
            <div class="">
              <button
                type="submit"
                v-if="!isLoginLoading"
                class="text-md w-full rounded bg-green-700 p-3 text-center font-semibold text-white"
                :class="{ 'bg-slate-300': !isValidPhone }"
                :disabled="!isValidPhone"
              >
                Se connecter
              </button>
              <loader-component v-else />
            </div>
            <div class="flex items-center justify-center gap-x-2">
              <div class="h-[1px] w-full border bg-slate-400"></div>
              <div>ou</div>
              <div class="h-[1px] w-full border bg-slate-400"></div>
            </div>
            <router-link
              :to="`${routes.register.path}`"
              class="text-md btn btn-primary w-full rounded bg-blue-700 p-3 text-center font-semibold text-white"
            >
              Créer un compte
            </router-link>
          </div>
        </form>
      </template>
    </template>
  </div>
</template>
